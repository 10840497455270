html[data-theme='light'] {
    --primary-default: #042338!important;
    --primary-lighter: #e3e3e3!important;
    --primary-light: #f5f5f5;
    --primary-dark: #323232;
    --primary-darker: #004274!important;

    --secondary-default: #004274!important;
    --secondary-lighter: #00599d!important;
    --secondary-light: #0070c6!important;
    --secondary-dark: #001f37!important;
    --secondary-darker: #003155!important;

    --accent-default: #004274!important;
    --accent-lighter: #00599d!important;
    --accent-light: #0070c6!important;
    --accent-dark: #001f37!important;
    --accent-darker: #003155!important;

    --positive-default: #28a745!important;
    --positive-lighter: #88bf70!important;
    --positive-light: #a0cb8c!important;
    --positive-dark: #568a3e!important;
    --positive-darker: #63a048!important;

    --negative-default: #b90505!important;
    --negative-lighter: #e10606!important;
    --negative-light: #f91717!important;
    --negative-dark: #7d0303!important;
    --negative-darker: #9b0404!important;
    /* BandwidthChart */
    --bandwidth-chart-color-0-theme: #004274!important;
    --bandwidth-chart-color-1-theme: #007bff!important;
    --bandwidth-chart-color-2-theme: #6f42c1!important;
    --bandwidth-chart-color-3-theme: #e83e8c!important;
    --bandwidth-chart-color-4-theme: #fd7e14!important;
    --bandwidth-chart-color-5-theme: #ffd200!important;
    --bandwidth-chart-color-6-theme: #28a745!important;

    /* RiskReturnChart */
    --risk-return-chart-color-0-theme: #004274!important;
    --risk-return-chart-color-1-theme: #007bff!important;
    --risk-return-chart-color-2-theme: #6f42c1!important;
    --risk-return-chart-color-3-theme: #e83e8c!important;
    --risk-return-chart-color-4-theme: #fd7e14!important;
    --risk-return-chart-color-5-theme: #ffd200!important;
    --risk-return-chart-color-6-theme: #28a745!important;
    
    --footer-bottom-color-theme: #fff!important;
    --border-radius-base: 0;
    --select-option-hover-color: #fff!important;
    --background-base: #edf5fb!important;
    --dropdown-menu-text-active-color-theme: #fff!important;
    --select-option-hover-color-theme: #fff!important;
    
} 
@font-face {
    font-family: OpenSans;
    src: url('../customizations/fonts/OpenSans-Regular.woff');
}
body h1, body h2, body h3, body h4, body h5, body h6, body p, body span, body div {   
    font-family: OpenSans, Roboto, Helvetica, Arial, sans-serif;   
}
.Table thead > tr th .table-cell__sorter .Icon {
    color: #fff!important;
}
.Table thead > tr th .table-cell__sorter {
    opacity: .7!important;
}
.form-group_focused .SelectButton {
    background-color: transparent!important;
}
.button-secondary.button.button-pressed, .button-secondary.button.button-pressed:active, .button-secondary.button:active, .button-secondary.button.active {
    color: #fff!important;
}
.application-title img {
    max-width: 200px;
}
.notifications-menubutton {
    color: #fff!important;
}
.PieChart .highcharts-pie {
    --pie-chart-colors-0: rgb(24, 156, 255);
    --pie-chart-colors-1: rgb(0, 136, 238);
    --pie-chart-colors-2: rgb(0, 112, 198);
    --pie-chart-colors-3: rgb(0, 89, 157);
    --pie-chart-colors-4: rgb(0, 66, 116);
    --pie-chart-colors-5: rgb(0, 54, 96);
    --pie-chart-colors-6: rgb(0, 43, 75);
}
.Highcharts-bar-chart {
    --bar-chart-color-0: rgb(24, 156, 255);
    --bar-chart-color-1: rgb(0, 136, 238);
    --bar-chart-color-2: rgb(0, 112, 198);
    --bar-chart-color-3: rgb(0, 89, 157);
    --bar-chart-color-4: rgb(0, 66, 116);
    --bar-chart-color-5: rgb(0, 54, 96);
    --bar-chart-color-6: rgb(0, 43, 75);
}
.LineChart {
    --line-chart-colors-0: rgb(24, 156, 255);
    --line-chart-colors-1: rgb(0, 136, 238);
    --line-chart-colors-2: rgb(0, 112, 198);
    --line-chart-colors-3: rgb(0, 89, 157);
    --line-chart-colors-4: rgb(0, 66, 116);
    --line-chart-colors-5: rgb(0, 54, 96);
    --line-chart-colors-6: rgb(0, 43, 75);
}