.Header__logo div {
    display: flex;
}

.Header__center .Header__logo div {
    justify-content: center;
}

.Header img {
    margin: 0;
    max-width: 200px;
}

.logo-text {
    margin-left: 10px;
    white-space: nowrap;
    align-self: end;
}

@media (max-width: 540px) {
    .Header__center .logo-text {
        display: none;
    }
}
