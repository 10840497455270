.proposal-details {
    margin-top: 0;
}

.proposal-details.container {
    margin-top: 0;
}

.proposal-details .risk-disclaimer-info-box {
    margin-bottom: var(--margin-base);
}

.proposal-details .overview {
    margin: 0 calc(-1 * var(--padding-base));
}

.proposal-details .overview .AccordionPanel {
    border-bottom: 0;
}

.proposal-details .overview .AccordionPanel-content-box {
    padding-bottom: var(--padding-base);
}
