.media {
    display: flex;
    align-items: center;
    margin-top: var(--margin-base);
}

.media-body {
    flex: 1;
}

.media-body .media-links {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--indent-base) / 3);
}

.media-body h3 {
    margin-bottom: 0;
}

.media-body a.call-now:before {
    position: relative;
    top: 3px;
    display: inline-block;
    margin-right: 14px;
    color: var(--link-color);
    content: '\2022';
    font-size: 1.8rem;
    line-height: 18px;
}

.media-body a.call-now {
    margin-left: 13px;
}

.media .picture {
    width: 72px;
    height: 72px;
    margin-right: 10px;
    border-radius: 50%;
}
