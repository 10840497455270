.review-section {
    margin-bottom: var(--padding-base);
    padding-bottom: var(--padding-base);
    border-bottom: var(--border-base);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.review-section h3 {
    margin-bottom: 8px;
}

.review-section .result-radio {
    text-align: center;
    margin-left: var(--padding-base);
}

@media (max-width: 767px) {
    .review-section {
        flex-direction: column;
    }

    .review-section .result-radio {
        margin-left: 0;
    }
}

.review-section:last-child {
    border-bottom: none;
    margin-bottom: var(--margin-base);
}

.review-section .result-radio .result-radio__label {
    display: inline-block;
    margin: 10px 0;
    font-size: 1.6rem;
    line-height: 1.375;
}

.review-section .button-link {
    --btn-fz: 14px;
    height: auto;
    color: var(--secondary-default);
    text-decoration: underline;
}

.review-section .button-link:hover {
    color: var(--primary-dark);
}
