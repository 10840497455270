.product-item {
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: var(--padding-base);
    align-items: center;
}

.product-item .image-wrapper {
    display: inline-block;
    width: 60px;
}

.product-item img {
    width: 100%;
}

.product-item p {
    margin-bottom: 0;
}

.product-item .Title {
    margin-bottom: calc(var(--margin-base) / 2);
}

@media (max-width: 767px) and (min-width: 576px) {
    .product-item {
        grid-column-gap: calc(var(--padding-base) / 2);
    }
}

@media (max-width: 576px) {
    .product-item {
        grid-template-columns: 1fr;
    }

    .product-item .image-wrapper {
        margin-bottom: var(--margin-base);
    }
}
