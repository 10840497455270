.ErrorBoundary.PageVariant {
    min-height: calc(100vh - var(--padding-base));
}

.ErrorBoundary .PageVariant .Content-box p {
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 200px;
    white-space: break-spaces;
}
