.FuturaePasscode p,
.FuturaePasscode a {
    color: var(--secondary-default);
}

.FuturaePasscode  a {
    display: block;
    margin-top: 20px;
    text-decoration: underline;
}

.FuturaePasscode  .info-text {
    margin-bottom: 20px;
}

.FuturaePasscode .Passcode .form-group + .form-group {
    margin-left: 11px;
}

.FuturaePasscode .Passcode input {
    width: 40px !important;
    height: 40px !important;
    font-size: 18px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    text-align: center !important;
}

.FuturaePasscode .Passcode .form-group:nth-child(4) {
    margin-left: 10px;
}
