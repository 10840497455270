.change-model-overview {
    padding: var(--padding-base) var(--content-box-p);
}

.change-model-overview > .row {
    width: calc(100% + 2 * var(--grid-column-padding));
}

.on-boarding .goal-review.goal-change-strategy .stepper-content .change-strategy-overview .risk-disclaimer-info-box {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: var(--padding-base);
}
