.goals-selection .stepper-content {
    padding-bottom: var(--padding-base);
}

.goals-selection .Info-box {
    margin-bottom: calc(var(--indent-base) * 2);
}

.goals-selection .select-goal {
    margin-bottom: var(--margin-base);
}
