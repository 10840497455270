.overview-panel {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}

.overview-panel:not(:last-child) {
    padding-bottom: var(--padding-base);
}

.overview-panel .row {
    min-width: 100%;
}

.overview-item + .overview-item {
    margin-top: var(--padding-base);
}

@media (max-width: 767px) {
    .overview-item {
        margin-bottom: calc(var(--padding-base) / 2);
        margin-top: 0 !important;
    }

    .overview-item + .overview-item:last-child {
        margin-bottom: 0;
    }
}

.overview-item {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    color: var(--primary-default);
}

.overview-item .Form-control-label > span {
    font-size: 1.6rem;
    line-height: 1.1875;
}

.overview-item__title {
    font-size: 1.6rem;
    line-height: 1.1875;
    font-weight: bold;
}

.overview-item__value {
    font-size: 3.2rem;
    line-height: 1.125;
}

.overview-item .overview-item__currency_large {
    font-size: 3.2rem;
    line-height: 1.125;
}

.overview-item .overview-item__value_medium,
.overview-item .overview-item__currency_medium {
    display: inline-block;
    height: auto !important;
    margin-top: calc(var(--indent-base) / 3);
    font-size: 20px;
    line-height: 24px !important;
}

.overview-item .overview-item__value_medium:not(.overview-item__value_positive):not(.overview-item__value_negative) {
    color: var(--secondary-default);
}

.overview-item .overview-item__value_medium + .overview-item__currency,
.overview-item .overview-item__currency_medium + .overview-item__currency {
    color: var(--secondary-default);
}

.overview-item__value_positive {
    color: var(--positive-default);
}

.overview-item__value_negative {
    color: var(--negative-default);
}

.overview-item .overview-item__currency_positive {
    color: var(--positive-default);
}

.overview-item .overview-item__currency_negative {
    color: var(--negative-default);
}

.overview-item__value_small {
    color: var(--secondary-default);
    font-size: 1.6rem;
    line-height: 2.25;
}

.overview-item__percent {
    margin-left: 10px;
    font-size: 1.6rem;
    color: var(--primary-default);
    line-height: 1.1875;
    font-weight: bold;
}

.overview-item__percent_positive {
    color: var(--positive-default);
}

.overview-item__percent_negative {
    color: var(--negative-default);
}

.overview-item__link {
    text-decoration: underline;
    color: var(--secondary-default);
    cursor: pointer;
}

a.Paragraph_secondary,
.Paragraph_secondary a,
a.Paragraph_secondary:active,
.Paragraph_secondary a:active,
a.Paragraph_secondary:focus,
.Paragraph_secondary a:focus {
    color: var(--secondary-default);
    text-decoration: underline;
}

a.Paragraph_secondary:hover,
.Paragraph_secondary a:hover {
    color: var(--secondary-dark);
}
