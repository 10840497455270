.discard-changes,
.execute {
    min-width: 410px;
}

.create-proposal {
    min-width: 405px;
}

.rebalance .Content-box > .row {
    margin-bottom: var(--padding-base);
    padding-bottom: 0;
}

.rebalance .content-wrapper {
    margin: 0 calc(-1 * var(--padding-base));
}

.rebalance .overview .info-box_error {
    margin-bottom: var(--padding-base);
}

.rebalance .overview-panel {
    margin-top: var(--padding-base);
    margin-bottom: calc(var(--margin-base) / 2);
}

@media (max-width: 767px) {
    .rebalance .overview-panel {
        margin-bottom: calc(var(--padding-base) / 2);
    }
}

.rebalance .overview-panel .row {
    width: 100%;
}

.rebalance .overview-panel .overview-item:not(:last-child) {
    padding-bottom: var(--padding-base);
}

.change-strategy .documents .Table thead > tr th:not(:first-child):last-child .table-cell__sorter .Icon {
    margin-left: 0.57142857em;
}

.change-strategy .documents .Table thead > tr th:not(:first-child):last-child {
    right: 30px;
}
