.details-knowledge-experience {
    display: flex;
    flex-direction: column;
    margin-top: var(--margin-base);
    border-bottom: var(--border-width-base) var(--border-style-base) var(--border-color);
}

.details-knowledge-experience h3 {
    margin-bottom: calc(var(--margin-base) / 2);
}

.details-knowledge-experience .answers-list {
    margin-bottom: calc(var(--margin-base) / 4);
}

.details-knowledge-experience:last-child {
    border-bottom: none;
}

.details-knowledge-experience button {
    margin-bottom: var(--margin-base);
}
