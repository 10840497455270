.documents .PageHeader {
    border-bottom: 1px solid var(--border-color);
}

.documents .PageHeader-title .Title {
    font-size: var(--title-2-fz);
}

.documents .documents-list {
    padding-top: 0;
}

.documents .content-right {
    text-align: right;
}

.documents .table-cell_linked {
    cursor: pointer;
    text-decoration: underline;
}

.documents .table-wrapper {
    width: auto;
    margin: 0 calc(var(--indent-base) * -2);
    padding: 0 calc(var(--indent-base) * 2);
}

.documents .table tr td.cell-fixed {
    background-color: transparent;
}

.documents .table-cell_disabled .table-cell_linked,
.documents .table-cell_disabled .table-cell_linked span {
    text-decoration: none;
}

.documents .Tabs-bar {
    border-bottom: none;
    margin-bottom: 0;
}

.documents tbody > tr td:not(:last-child) {
    padding-right: var(--table-filter-width);
}

.Modal .documents-input {
    margin: 2rem 0;
    min-height: 100px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.Modal .documents-input .form-group {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 100%;
}

.Modal .documents-input .form-group .FilesList {
    max-width: 100%;
}
