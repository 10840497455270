.risk-profile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 182px);
    margin-bottom: var(--margin-base);
}

.risk-profile > .stepper-content {
    max-width: 100%;
    margin: 0;
}

.risk-profile h2.Title {
    padding-bottom: var(--padding-base);
    padding-left: var(--padding-base);
    padding-right: var(--padding-base);
    margin-bottom: 0;
    margin-left: calc(-1 * var(--padding-base));
    margin-right: calc(-1 * var(--padding-base));
    border-bottom: var(--border-width-base) var(--border-style-base) var(--border-color);
}

@media only screen and (max-width: 576px) {
    .risk-profile h2 {
        font-size: 1.8rem;
        line-height: 1.22222222;
    }

    .risk-profile .Steps-item {
        width: 25%;
    }

    .risk-profile .Steps-item-title {
        font-size: 1.2rem;
        line-height: 1.16666667;
    }

    .risk-profile .Steps-item-icon {
        margin-left: calc(50% - 16px);
    }

    .risk-profile .Steps-item-tail {
        margin-left: 50%;
    }

    .risk-profile .Steps-item-content {
        width: auto;
        max-width: 100%;
        padding: 0 5px;
    }
}

.risk-profile .Steps-container {
    margin: var(--padding-base) 0;
    max-width: 100%;
    width: auto;
}

.risk-profile .questionnaire-group-content {
    margin: var(--padding-base) auto;
    max-width: 700px;
    width: 100%;
}

.risk-profile .stepper-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    border-top: var(--border-base);
}

.risk-profile .stepper-buttons .button-primary {
    margin-left: auto;
}

.risk-logo {
    cursor: pointer;
}
