.single-news-item {
    margin-bottom: 40px !important;
}

.single-news-item .Content-box .button {
    min-width: 60px;
    margin-left: var(--margin-base);
}

.single-news-item hr {
    display: block;
    height: 1px;
    margin: var(--padding-base) calc(-1 * var(--padding-base));
    border: none;
    color: var(--secondary-lighter);
    background-color: var(--secondary-lighter);
}

.single-news-item--image {
    max-width: 100%;
}

@media (max-width: 767px) {
    .single-news-item--image {
        margin: var(--margin-base) 0;
    }
}

.single-news-item--date {
    min-width: 100px;
    line-height: 1.7;
    margin-bottom: 10px;
}

.single-news-item--content {
    margin-bottom: 9px;
    white-space: pre-line;
}

@media (max-width: 767px) {
    .single-news-item--content {
        text-align: justify;
    }
}

@media (max-width: 767px) {
    .single-news-item hr {
        display: none;
    }
}
