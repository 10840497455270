.table-cell_status-green {
    color: var(--positive-default);
}

.table-cell_status-red {
    color: var(--negative-default);
}

.table-cell_status-yellow {
    color: var(--warning-yellow);
}

.table-cell_status-black {
    color: var(--primary-default);
}
