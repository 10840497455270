.investment-allocation-compare .list-chart {
    border-top: none;
}

.investment-allocation-compare .list-chart .current {
    margin-top: calc(var(--margin-base) * -1);
}

.investment-allocation-compare .list-chart .new {
    border-bottom: none;
    margin-bottom: calc(var(--margin-base) * -1);
}

.investment-allocation-compare .PieChart .highcharts-pie {
    width: 100%;
    justify-content: center;
}

@media screen and (max-width: 1023px) {
    .investment-allocation-compare .PieChart .highcharts-pie {
        flex-direction: column;
        align-items: center;
    }

    .PieChart .highcharts-pie .legend {
        justify-content: center;
    }

    .PieChart .highcharts-pie .legend-item {
        margin-left: 0;
    }
}

.list-chart .AccordionPanel-header,
.list-chart .AccordionPanel-content-box {
    padding-left: 0;
}
