.color-group {
    margin: var(--padding-base) 0;
    padding-bottom: var(--padding-base);
    border-bottom: var(--border-base);
}

.color-group:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
}

.color-box {
    border-radius: 4px;
    box-shadow: var(--content-box-shadow);
}

.color-box .Paragraph_primary {
    padding: 10px 5px;
}

.color-box__color {
    display: block;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    height: 110px;
    width: 100%;
    border: none;
    cursor: pointer;
}

.color-group__items {
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    grid-gap: 30px;
}

.color-box__picker-tooltip .rc-tooltip-inner {
    padding: 0;
}

.color-picker-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
