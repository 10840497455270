.LanguageSwitcher .select {
    --select-btn-border: var(--border-width-base) var(--border-style-base) var(--language-select-btn-border-color, var(--select-button-border-color-base-theme));
    --select-button-border-color-base: var(--language-select-button-border-color-base, var(--select-button-border-color-base-theme));
    --select-button-focused-bg: var(--language-select-button-focused-bg, var(--select-button-focused-bg-theme));
}

.LanguageSwitcher .select__dropdown {
    --select-dd-bg: var(--language-select-dropdown-bg, var(--select-dropdown-bg-theme));
    --select-dd-border: var(--border-width-base) var(--border-style-base) var(--language-select-dd-border, var(--select-dropdown-border-color-theme));
}

.LanguageSwitcher .select__option .checkbox-wrapper {
    --select-option-wrapper-color: var(--language-select-option-wrapper-color, var(--select-option-wrapper-color-theme));
}

.LanguageSwitcher .select button {
    color: var(--language-select-btn-color, inherit);
}

.LanguageSwitcher .select .Icon.anticon__arrow {
    color: var(--language-select-btn-border-color, inherit);
}

.LanguageSwitcher .select .select__dropdown__wrapper {
    bottom: 100%;
    top: unset;
}

.LanguageSwitcher .select .select__dropdown {
    border-radius: 3px 3px 0 0;
}
