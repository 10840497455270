.start-page {
    min-height: calc(100vh - 212px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-page .Title {
    font-size: 2.1rem;
    margin-bottom: 15px !important;
}

.start-page .Info-box__button {
    margin-top: var(--padding-base);
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.start-page .Info-box div {
    text-align: left;
}
