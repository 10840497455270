.ratings {
    margin-top: calc(var(--indent-base) / 3);
    margin-left: calc(var(--margin-base) / -2);
    margin-bottom: calc(var(--margin-base) * -1 - 5px);
}

.ratings .field {
    display: flex;
    margin: 0 calc(var(--margin-base) / 2) calc(var(--margin-base) / 2) calc(var(--margin-base) / 2);
    padding-bottom: var(--indent-base);
    padding-top: 10px;
    justify-content: space-between;
    border-bottom: var(--border-base);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    color: var(--primary-default);
}

.ratings .field .field-name {
    font-weight: bold;
}

.ratings .widget__empty {
    width: 100%;
    margin-bottom: var(--indent-base)!important;
}

@media only screen and (min-width: 1024px) {
    .ratings {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 200px;
    }

    .ratings .field {
        width: 48%;
    }

    .ratings.ratingsRowsDesktop1 .field:nth-child(1n),
    .ratings.ratingsRowsDesktop2 .field:nth-child(2n),
    .ratings.ratingsRowsDesktop3 .field:nth-child(3n),
    .ratings.ratingsRowsDesktop4 .field:nth-child(4n) {
        border-bottom: none;
    }
}

@media (min-width: 577px) and (max-width: 1023px) {
    .ratings {
        column-count: 2;
        margin-bottom: 0;
    }

    .ratings.tabletLastItem1 .field:nth-child(1n),
    .ratings.tabletLastItem2 .field:nth-child(2n),
    .ratings.tabletLastItem3 .field:nth-child(3n),
    .ratings.tabletLastItem4 .field:nth-child(4n),
    .ratings.tabletLastItem5 .field:nth-child(5n),
    .ratings.tabletLastItem6 .field:nth-child(6n),
    .ratings.tabletLastItem7 .field:nth-child(7n),
    .ratings.tabletLastItem8 .field:nth-child(8n),
    .ratings.tabletLastItem9 .field:nth-child(9n) {
        border-bottom: none;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 576px) {
    .ratings {
        column-count: 1;
    }

    .ratings .field:last-child {
        border-bottom: none;
    }
}
