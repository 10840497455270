.buttons-block-row {
    --buttons-block-row-bg: var(--background-content);
    --buttons-block-row-padding: var(--padding-base);
    --buttons-block-row__button-margin: var(--margin-base);
    --buttons-block-row__border: var(--border-width-base) var(--border-style-base) var(--border-color);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: var(--buttons-block-row-padding) 0;
    background-color: var(--buttons-block-row-bg);
}

.Content-box + .buttons-block-row {
    padding-left: var(--buttons-block-row-padding);
    padding-right: var(--buttons-block-row-padding);
}

.Content-box > .buttons-block-row {
    border-top: var(--buttons-block-row__border);
    margin-left: calc(var(--content-box-p) * -1);
    margin-right: calc(var(--content-box-p) * -1);
    padding-left: var(--content-box-p);
    padding-right: var(--content-box-p);
}

.Content-box > .AccordionWrapper + .buttons-block-row {
    border-top: none;
}

.Content-box > .Accordion + .buttons-block-row {
    border-top: none;
}

.Content-box > .buttons-block-row:last-child {
    padding-bottom: 0;
}

.buttons-block-row .buttons-block__content {
    width: 100%;
}

.buttons-block-row .buttons-block__content:not(:empty) {
    padding-bottom: var(--buttons-block-row-padding);
}

.buttons-block-row .buttons-block__content .Info-box {
    margin-top: 0;
}

.buttons-block-row .buttons-block__col:nth-child(2n) {
    min-width: 1px;
}

.buttons-block-row .buttons-block__col:last-child {
    display: flex;
    text-align: right;
}

.buttons-block-row .buttons-block__col:last-child .button:not(:first-child) {
    margin-left: var(--buttons-block-row__button-margin);
}

@media (max-width: 576px) {
    .buttons-block-row {
        flex-direction: column-reverse;
    }

    .buttons-block-row .buttons-block__col {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
    }

    .buttons-block-row .buttons-block__col .button {
        width: 100%;
        margin-bottom: 0;
        margin-left: 0;
    }

    .buttons-block-row .buttons-block__col .button:last-child {
        margin-bottom: 0;
        margin-left: 0;
    }

    .buttons-block-row .buttons-block__col:last-child .button:not(:first-child) {
        margin-left: 0;
    }

    .buttons-block-row .buttons-block__col:last-child .button:first-child {
        margin-left: 0;
        margin-bottom: var(--buttons-block-row__button-margin);
    }

    .buttons-block-row .buttons-block__col:last-child .button:nth-child(2) {
        margin-bottom: var(--buttons-block-row__button-margin);
    }

    .buttons-block-row .buttons-block__col:first-child .button:first-child {
        margin-top: 0;
    }

    .buttons-block-row .buttons-block__col:last-child .button:last-child {
        margin-left: 0;
    }
}
