.client-details .loading-overlap {
    height: 100%;
}

.client-details .details-section {
    margin-top: var(--padding-base);
    border-bottom: 1px solid var(--secondary-lighter);
}

.client-details .details-section .col-7 {
    padding-right: 0;
}

.client-details .col-buttons {
    padding-top: 5px;
}

.client-details .col-buttons button {
    margin-left: var(--margin-base);
}

.client-details .has-feedback .form-control {
    padding-right: 0;
}

/* Accordion component */
.client-details .custom-components > .custom-component {
    display: none;
}

.client-details .custom-components > .custom-component.active {
    display: block;
}

.client-details .details-form-io .formio-form {
    min-height: 0;
}

.client-details .details-form-io {
    margin-top: calc(var(--margin-base) * -1);
}
