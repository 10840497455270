.ChangeRiskModal > .Info-box {
    margin-top: var(--margin-base);
}

.ChangeRiskModal > .Paragraph {
    margin-bottom: var(--margin-base);
}

.ChangeRiskModal > .form-group .checkbox-wrapper {
    margin-top: calc(var(--indent-base) + 1px);
}

.ChangeRiskModal > .form-group .checkbox-wrapper .checkbox {
    margin-top: calc((24px - var(--checkbox-size)) / 2);
}

.ChangeRiskModal > .form-group .checkbox-wrapper .checkbox-label {
    line-height: 24px;
}
