.MTanPasscode a,
.MTanPasscode .info-text {
    color: var(--secondary-default);
}

.MTanPasscode a {
    display: block;
    margin-top: 20px;
    text-decoration: underline;
}

.MTanPasscode .info-text,
.MTanPasscode h3,
.MTanPasscode p {
    margin-bottom: 20px !important;
}

.MTanPasscode .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-right-color: transparent !important;
}

.MTanPasscode .Passcode {
    margin-bottom: calc(var(--margin-base) + 4px);
}

.MTanPasscode .Passcode input {
    width: 28px !important;
    height: 32px !important;
    font-size: 18px !important;
    padding: 0 5px !important;
    border-width: 0 0 1px !important;
    border-radius: 0 !important;
    text-align: center !important;
}

.MTanPasscode .Passcode input,
.MTanPasscode .Passcode input:hover,
.MTanPasscode .Passcode input:focus {
    outline: 0 !important;
    border-right-color: transparent !important;
}

.MTanPasscode .Passcode .form-group {
    display: inline-block;
}

.MTanPasscode .Passcode .form-group + .form-group {
    margin-left: 3px;
}

.MTanPasscode button {
    margin-bottom: 0 !important;
}

.MTanPasscode .buttons a,
.MTanPasscode .buttons a:hover {
    font-size: 1.6rem !important;
    text-decoration: underline;
    color: var(--secondary-default);
}
