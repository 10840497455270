.list-preview {
    --mb: 10px;
    --ml: 10px;

    margin-top: var(--padding-base);
    margin-bottom: var(--mb);
}

.list-preview .search-wrapper {
    margin: 0 auto var(--padding-base);
    width: 75%;
}

.list-preview .widget__empty {
    border: 0;
}

.list-preview .Tabs .Tabs-bar {
    margin-bottom: 0;
    padding-left: var(--padding-base);
}

.list-preview .Tabs .TabPane .info-box_accent {
    margin-top: var(--padding-base);
}

.list-preview__content {
    margin: calc(var(--padding-base) * -1) calc(var(--padding-base) * -1);
    padding: 0 var(--indent-base) 0 0;
    border-top: 1px solid var(--border-color);
}

.list-preview .preview-container {
    position: relative;
    padding: 0;
    height: 100%;
    border-left: 1px solid var(--border-color);
    margin-right: calc(var(--indent-base) * -1);
}

.list-preview__list {
    padding: 0 0 var(--padding-base);
    margin-right: -1px;
}

.list-preview .list-preview__list .Table-wrapper .Pagination {
    border: none;
}

.list-preview .list-preview__list .Table tbody>tr.row-level-0 {
    border-bottom: 1px solid var(--border-color);
}

.container .list-preview .list-preview-title .Title {
    margin-bottom: 0;
}

.list-preview .list-preview-title div {
    align-self: center;
}

@media (min-width: 992px) {
    .list-preview .min-heigth {
        min-height: 500px;
    }

    .list-preview .preview-content .button-group {
        margin: 0 calc(var(--padding-base) * -1) var(--padding-base) calc(var(--padding-base) * -1);
    }
}

@media (max-width: 992px) {
    .list-preview .preview-container {
        border-left: none;
    }

    .list-preview .preview-container .preview-content {
        border-left: none;
    }

    .list-preview .preview-content__inner {
        margin: calc(var(--padding-base) * -1) 0;
    }

    .list-preview .preview-content .Paragraph_primary {
        margin-top: calc(var(--margin-base) * -1);
    }

    .list-preview__content {
        padding: 0;
        margin-top: var(--padding-base);
    }

    .list-preview .preview-content .button-group {
        margin-right: calc(var(--indent-base) * -1);
    }
}

@media (max-width: 575px) {
    .list-preview .list-preview__content {
        margin: 0 calc(var(--indent-base) * -1);
        padding: 0;
    }

    .list-preview .list-preview__list {
        padding: 0;
    }

    .preview-container .preview-content .button-group {
        margin: 0 0 0 calc(var(--indent-base) * -1);
        padding: var(--indent-base);
    }

    .list-preview .preview-content__inner {
        margin-top: 0;
    }

    .list-preview .preview-content {
        padding: var(--indent-base) var(--indent-base) 0;
    }

    .list-preview .Tabs .Tabs-bar {
        padding-left: var(--indent-base);
    }

    .list-preview .list-preview__content .Tabs .Table.Table-mini tr td:first-child {
        padding-left: var(--indent-base) !important;
    }

    .list-preview .preview-content .Paragraph_primary {
        margin-top: calc(var(--padding-base) * -1);
        margin-bottom: var(--indent-base);
    }

    .list-preview .Tabs .Table-header-mini {
        padding-right: var(--indent-base);
    }

    .list-preview .list-preview-title button {
        margin-left: var(--ml);
    }

    .list-preview .preview-content .preview-content-metadata,
    .list-preview .preview-content .preview-content-attachments {
        margin-right: var(--indent-base);
    }
}
