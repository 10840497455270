.confirmation-disclaimer .toggle-block {
    display: flex;
    align-items: center;
}

.confirmation-disclaimer .toggle-block .Toggle {
    margin-right: calc(var(--margin-base) / 2);
}

.confirmation-disclaimer .toggle-block span {
    color: var(--primary-light);
    line-height: 1;
}

.confirmation-disclaimer .error-message {
    color: var(--form-control-error-color-theme);
    margin-top: 2px;
}
