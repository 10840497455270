.ThemeToggle {
    --theme-toggle-pb: calc(var(--padding-base) / 2);

    width: calc(100% + var(--profile-menu-content-p) * 2);
    border-top: var(--border-base);
    margin-left: calc(var(--profile-menu-content-p) * -1);
    margin-right: calc(var(--profile-menu-content-p) * -1);
    margin-bottom: calc(var(--profile-menu-content-p) * -1);
    padding-bottom: var(--theme-toggle-pb);
}

.ThemeToggle .TabPane {
    padding-bottom: var(--tabs-bar-mb);
}

.ThemeToggle .form-group {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ThemeToggle .Toggle {
    margin-right: calc(var(--margin-base) / 2);
}
