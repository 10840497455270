header {
    margin-bottom: 1px;
}

header.header {
    min-height: 80px;
}

header.header img {
    margin: 0;
    max-width: 200px;
}

.profile-menu__name {
    color: var(--primary-default);
}

.profile-menu__additional {
    line-height: 20px;
}

.profile-menu__footer .dark-theme-togle h4 {
    color: var(--primary-default);
    margin-bottom: 0;
    font-weight: bold;
}

.profile-menu__footer .dark-theme-togle {
    display: flex;
    align-items: center;
    padding: var(--indent-base) var(--margin-base) 0;
    border-top: var(--border-base);
    justify-content: space-between;
    margin: 0 calc(var(--margin-base) * -1);
    margin-bottom: -4px;
}
