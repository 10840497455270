.image-svg {
    display: block;
    width: 100%;
    background-color: var(--secondary-default);
    mask-size: cover;
}

.image-svg:after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.image-with-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 200px;
    background-color: var(--bright);
    background-size: contain;
    color: var(--bright);
    font-size: 0;
}

.image-with-placeholder svg {
    display: inline-block;
    width: 100%;
    fill: var(--secondary-default);
}
